import * as React from "react";
import Layout from "@layout";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo/dark-logo.png" }) {
        id
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <Layout location={location}>
      <Header />
      <main className="site-wrapper-reveal" style={{ textAlign: "center" }}>
        <GatsbyImage
          image={data.file.childImageSharp.gatsbyImageData}
          alt="Orange"
        />
      </main>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
